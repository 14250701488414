<template>
  <v-navigation-drawer
    v-model="drawer"
    :disable-route-watcher="$vuetify.breakpoint.smAndUp"
    app
    disable-resize-watcher
    right
    width="375"
  >
    <v-list>
      <v-list-item>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            :key="icon"
            class="mx-4 black--text"
            icon
            block
            rounded="false"
            @click="scrollTotop"
          >
            <v-icon size="24px">
              {{ icon_home }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-list-item>
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-list-tile class="d-flex justify-center">
            <v-row
              class="align-right"

              justify="center"
            >
              <v-btn
                block
                text
                rounded="false"
              >
                {{ item.title }}
              </v-btn>
            </v-row>
          </v-list-tile>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items_services_sub"
            :key="index"
          >
            <v-row
              justify="center"
              align="center"
            >
              <v-btn
                block
                small
                rounded="false"
                @click="scrollToservices(item.div)"
                v-html="m_items_services(index)"
              />
            </v-row>
          </v-list-item>
        </v-list>
      </v-list-group>
      <v-list-item>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            block
            rounded="false"
            text
            @close="drawer = !drawer"
            @click="scrollTomarket"
          >
            {{ this.$t('message.market') }}
          </v-btn>
        </v-row>
      </v-list-item>
      <v-list-item>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            block
            rounded="false"
            text
            @click="scrollTonews"
          >
            {{ this.$t('message.news') }}
          </v-btn>
        </v-row>
      </v-list-item>
      <v-list-item>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            block
            rounded="false"
            text
            @click="scrollToabout"
          >
            {{ this.$t('message.aboutus') }}
          </v-btn>
        </v-row>
      </v-list-item>
      <v-list-item>
        <v-row
          justify="center"
          align="center"
        >
          <v-btn
            block
            rounded="false"
            text
            @click="scrollTocontact"
          >
            {{ this.$t('message.contact') }}
          </v-btn>
        </v-row>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'FrontendDrawer',
    data: () => ({
      items: [
        {
          action: 'services',
          title: 'Services',
          active: true,
          items: [
            { title: 'services1' },
            { title: 'services2' },
            { title: 'services3' },
          ],
        },
      ],
      items_services_sub: [
        { div: 'services1' },
        { div: 'services2' },
        { div: 'services3' },
      ],
      icon_home: '$mdiHome',
      disabled: false,
      absolute: false,
      openOnHover: true,
      value: false,
      closeOnClick: true,
      closeOnContentClick: true,
      offsetX: false,
      offsetY: true,
      disabled_services: false,
      absolute_services: false,
      openOnHover_services: true,
      value_services: false,
      closeOnClick_services: true,
      closeOnContentClick_services: true,
      offsetX_services: false,
      offsetY_services: true,
    }),

    computed: {
      ...sync('frontend/*'),
    },
    methods: {
      changeLocale: function (locale) {
        this.$i18n.locale = locale
      },
      m_items_services: function (index) {
        return this.$t('message.ourservices_sub')[index]
      },
      top () {
        window.scrollTo(0, 0, 'smooth')
      },

      scrollTotop: function () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('topdiv')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToservices: function (adiv) {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById(adiv)
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },

      scrollTomarket () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('market')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollTonews () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('news')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToabout () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('about')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollTocontact () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('contact')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
    },
  }
</script>
